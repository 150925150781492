import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import SimilarProjects from './../Elements/SimilarProjects';
import Banner from './../Elements/Banner';
import ReactPlayer from 'react-player';
import { productsData } from '../../data';
import { useParams } from 'react-router-dom'; // Import useParams to get URL parameters
import SimilarProducts from '../Elements/SimilarProducts';
import Faq from '../Elements/Faq';
import OurValue3 from '../Elements/OurValueD';
import Loader from '../Elements/Loader';
import axios from 'axios';
const bnrimg = require('./../../images/banner/5.jpg');


const ProductDetail = () => {

    const products = productsData;
    const { slug } = useParams(); // Get the slug from URL
    const [product, setProduct] = useState(null);
    const [similarProducts, setSimilarProducts] = useState([]);
    const [productImages, setProductImages] = useState([]);
    const [about, setAbout] = useState({});


   
    useEffect(() => {
        const fetchProduct = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/product/${slug}`);
                setProduct(response.data);
                setProductImages(JSON.parse(response.data.images));
            console.log("Product : ",response.data);
        };

        const fetchAbout = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/about_info`);
            setAbout(response.data);
            console.log("About Info : ",response.data);
        };
        fetchProduct();
        fetchAbout();

    }, [slug]);
  
    if (!product) {
        return <Loader/>; // Show loading state while fetching
    }  
    
    
    
    const options = {
        loop: true,
        autoplay: true,
        center: false,
        items: 3,
        margin: 40,
        nav: true,
        dots: false,
        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            991: {
                items: 3
            },
            1200: {
                items: 4
            }
        }
    };

    return (
        <>
            <Header />
            <div className="page-content">
            
                <Banner title={product.title} pagename={product.title} bgimage={ `${process.env.REACT_APP_API_URL}/storage/${product.banner}` } />

                {/* SECTION CONTENT START */}
                <div className="section-full p-tb80 inner-page-padding">
                    <div className="container">
                        <div className="project-detail-outer">
                            
                            <div className="m-b30">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="product-block">
                                            <div className="row">
                                                <div className="col-md-12 m-b10">
                                                    <h4 className="text-uppercase font-weight-600 m-b10">{product.title}</h4>
                                                    <p dangerouslySetInnerHTML={{ __html: product.short_description }}>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="mt-box">
                                            <div className="mt-thum-bx mt-img-overlay1 mt-img-effect yt-thum-box">
                                                <img style={{height: '400px'}} src={ `${process.env.REACT_APP_API_URL}/storage/${product.banner}` } alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="project-detail-containt">
                                <div className="bg-white text-black">
                                    {/* <h3 className="font-weight-600">{ product.title}</h3> */}
                                    <p dangerouslySetInnerHTML={{ __html: product.description }}>
                                    </p>
                                </div>
                            </div>

                            <div className="m-b0">
                                <div className="row">
                                   { productImages &&
                                    productImages.map((img, index) => (
                                     <div className="col-md-4 col-sm-4" key={index}>
                                        <div className="project-detail-pic m-b30">
                                            <div className="mt-media">
                                                <img src={ `${process.env.REACT_APP_API_URL}/storage/${img}` } alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                <div className="section p-b50 p-t50 bg-repeat square_shape2 bg-moving" >
                    <div className="container">
                        {/* TITLE START */}
                        {/* <div className="section-head">
                            <div className="mt-separator-outer separator-center">
                                <div className="mt-separator">
                                    <h2 className="text-black sep-line-one "><span className="font-weight-600 text-primary">Similar</span> Products</h2>
                                </div>
                            </div>
                        </div> */}
                        {/* TITLE END */}
                    </div>
                    {/* <div className="section-content container-fluid">
                        <div className="similar-carousel-outer">
                            <OwlCarousel className="owl-carousel similar-products owl-btn-vertical-center" {...options}>
                                {similarProducts && similarProducts.map((item, index) => (
                                        <div key={index} className={`${item.filter} masonry-item `}>
                                            <div className="image-effect-one hover-shadow">
                                                <img style={{ height: '260px' }} src={item.thumb} alt="" />
                                                <div className="figcaption" style={{padding:'10px !important'}}>
                                                    <h4>{item.title}</h4>
                                                    <p>{item.short_description.length > 50 ? item.short_description.substring(0, 50) + '...' : item.short_description}</p>
                                                    <NavLink to={`/products/${item.slug}`} className={"text-primary button"} style={{display:'flex', fontWeight:'bold', justifyContent:'end', padding:'10px'}}>
                                                        Read More ... 
                                                    </NavLink>  
                                                </div>
                                                <a className="mfp-link" href={item.image}>
                                                    <i className="fa fa-arrows-alt" />
                                                </a>
                                            </div>
                                        </div>
                                    ))}
                            </OwlCarousel>
                        </div>
                    </div> */}
                </div>

                {/* <Faq /> */}

                </div>
                <OurValue3  about ={about} />

                {/* SECTION CONTENT END */}
                {/* <SimilarProducts  products= {similarProducts}/> */}

            </div>
            <Footer />
        </>
    );
};

export default ProductDetail;
