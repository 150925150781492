import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import Banner from "../Elements/Banner";
import { filtersData, productsData } from "../../data";
import axios from "axios";
import OurValue3 from "../Elements/OurValueD";
var bnrimg = require("./../../images/products/banner.jpg");
function ProductPage() {
  const products = productsData;
  const filters = filtersData;

  const [visibleItems, setVisibleItems] = useState(9);
  const [hasMore, setHasMore] = useState(true);

  const [categories, setCategories] = useState(null);
  const [productsD, setProducts] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("*"); // State for selected category
  const [about, setAbout] = useState({});
  const [filteredProducts, setfilteredProducts] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // New state for search term

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/products-list`
      );
      setProducts(response.data);
      console.log("Products  : ", response.data);
    };

    const fetchAbout = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/about_info`
      );
      setAbout(response.data);
      console.log("About Info : ", response.data);
    };
    const fetchCategories = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/categories`
      );
      setCategories(response.data);
      console.log("Categories : ", response.data);
    };
    fetchProducts();
    fetchCategories();
    fetchAbout();
  }, []);

  const truncateText = (text) => {
    const maxLength = 50;
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  useEffect(() => {
    if (visibleItems >= products.length) {
      setHasMore(false); // No more products to load
    }
  }, [visibleItems]); // This effect will trigger whenever visibleItems changes

  useEffect(() => {
    let filtered = productsD;

    // Apply category filter
    if (selectedCategory !== "*") {
      filtered = filtered?.filter(
        (product) => product.product_category_id == selectedCategory
      );
    }

    // Apply search filter
    if (searchTerm) {
      filtered = filtered?.filter((product) =>
        product.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setfilteredProducts(filtered);
  }, [selectedCategory, productsD, searchTerm]);
  const handleLoadMore = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 9); // Increase visible items by 2
  };

  const handleCategoryFilter = (categoryId) => {
    setSelectedCategory(categoryId); // Update selected category
    setVisibleItems(productsData.length); // Reset visible items
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value); // Update search term
  };
  return (
    <div className="section-full p-tb80 bg-gray inner-page-padding">
      <div className="row">
        <div className="col-md-3 filter-wrap p-b30 text-center">
          <ul className="CategoriesTag">
            <li className="head">Categories</li>
            {categories && categories.length > 0 ? (
              <>
                <li className={selectedCategory === "*" ? "active" : ""}>
                  <li
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: "16px",
                      // make it uppercase
                      textTransform: "uppercase",
                      color: selectedCategory === "*" ? "#0DA6C2" : "black",
                    }}
                  >
                    <span onClick={() => handleCategoryFilter("*")}>
                      All Products
                    </span>
                  </li>
                </li>
                {categories.map((item, index) => (
                  <li
                    key={index}
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                      fontSize: "16px",
                      color: selectedCategory === item.id ? "#0DA6C2" : "black", // Red for active category
                    }}
                  >
                    <span onClick={() => handleCategoryFilter(item.id)}>
                      {item.name}
                    </span>
                  </li>
                ))}
              </>
            ) : (
              <div></div>
            )}
          </ul>
        </div>
        <div
          className="col-md-9 mfp-gallery work-grid row clearfix "
          style={{ paddingLeft: "32px" }}
        >
          <div
            style={{
              margin: "10px 5px ",
              borderRadius: "30px",
            }}
          >
            <input
              name="search"
              type="search"
              placeholder="Search Product Here"
              className="form-control"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
          {filteredProducts && filteredProducts.length > 0
            ? filteredProducts.slice(0, visibleItems).map((item, index) => (
                <div
                  style={{
                    padding: "0px 5px",
                  }}
                  key={index}
                  className={`${item.product_category_id} masonry-item col-lg-4 col-md-4 col-sm-6 m-b30`}
                >
                  <div
                    style={{
                      boxShadow: "0px 0px 5px",
                      overflow: "hidden", 
                      position: "relative",
                    }}
                    className="product-card"
                  >
                    <div className="image-container">
                      <img
                        className="product-image"
                        style={{
                          width: "100%",
                          height: "200px",
                          transition: "transform 0.3s ease-in-out",
                        }}
                        src={`${process.env.REACT_APP_API_URL}/storage/${item.banner}`}
                        alt=""
                      />
                    </div>
                    <div className="p-4" style={{ padding: "10px !important" }}>
                      <h4 style={{ padding: "5px 5px 0px 5px" }}>
                        {item.title.length > 25
                          ? item.title.substring(0, 25) + "..."
                          : item.title}
                      </h4>
                      <NavLink
                        to={`/products/${item.slug}`}
                        className={"text-primary button"}
                        style={{
                          display: "flex",
                          fontWeight: "bold",
                          justifyContent: "end",
                          padding: "10px",
                        }}
                      >
                        Read More ...
                      </NavLink>
                    </div>
                  </div>
                </div>
              ))
            : products.map((item, index) => (
                <div
                  key={index}
                  className="col-md-4 col-sm-6"
                  style={{ padding: "2px" }}
                >
                  <div className="shimmer-effect">
                    <div className="shimmer shimmer-image" />
                    <div className="shimmer shimmer-text" />
                    <div
                      className="shimmer shimmer-text"
                      style={{ width: "80%" }}
                    />
                    <div className="shimmer shimmer-icons" />
                  </div>
                </div>
              ))}
          {filteredProducts && filteredProducts.length > 6 && (
            <div class="text-center m-t30">
              <button
                onClick={handleLoadMore}
                style={{
                  cursor: "pointer",
                }}
                class=" site-button"
              >
                Load More
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductPage;
