import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { servicesData } from '../../data';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
var bnr1 = require('./../../images/background/bg-6.png');
const servicesD = servicesData.filter((item)=>item.featured==true);

const FeaturedServices = ({featured_services})=> {

    const [featuredData, setFeaturedData]=useState(null);
    const truncateText = (text) => {
        const maxLength = 100;
        if (text.length > maxLength) {
          return text.substring(0, maxLength) + '...';
        }
        return text;
      };

      const truncateTitle = (text) => {
        const maxLength = 40;
        if (text.length > maxLength) {
          return text.substring(0, maxLength) + '...';
        }
        return text;
      };


      useEffect(()=>{
        function loadScript(src) {

            return new Promise(function(resolve, reject){
              var script = document.createElement('script');
              script.src = src;
              script.addEventListener('load', function () {
                resolve();
              });
              script.addEventListener('error', function (e) {
                reject(e);
              });
              document.body.appendChild(script);
              document.body.removeChild(script);
            })
          };
  
        loadScript('./assets/js/masonary.js');
    },[]);

    useEffect(()=>{
        setFeaturedData(featured_services)
    },[featured_services])

    const options = {
        loop:true,
        autoplay:false,
        center: false,
        items:3,
        margin:40,
        nav:true,
        dots: false,
        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        responsive:{
            0:{
                items:1
            },
            768:{
                items:2
            },			
            991:{
                items:3
            },
            1200:{
                items:4
            }			
            
        }
    };

    console.log("FS : ",featured_services);
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-dark mobile-page-padding" style={{backgroundImage:"url(" + bnr1 + ")"}}>
                    <div className="container">
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="section-head m-b50 text-white text-center">
                                <h3 className="m-t0">Our Premier Services.</h3>
                                <p>Innovative Solutions, Exceptional Results: Delivering Quality Across Industries.</p>
                            </div>
                            <div className="row">
                            <div className="section-content">
                                <div className="work-carousel-outer">
                                    <OwlCarousel className="owl-carousel work-carousel owl-btn-vertical-center" {...options}>
                                        {
                                            featured_services && featured_services.length>0 ?
                                            (featured_services.map((item, index) => (
                                                <div className=" m-b30" key={index}>
                                                    <div className="image-effect-one hover-shadow customF">
                                                        <img src={ `${process.env.REACT_APP_API_URL}/storage/${item.banner}` }  style={{height:'350px'}} alt=""/>
                                                        <div className="figcaption figcaption2"
                                                        style={{
                                                            transform: "translateY(0) !important",
                                                            opacity: '1 !important'
                                                        }}
                                                        >
                                                            <h5>{item.serviceName}</h5>
                                                            {/* <p dangerouslySetInnerHTML={{ __html: truncateText(item.short_description) }}></p> */}

                                                            <NavLink to={`/services/${item.slug}`} className={"text-primary button"} style={{display:'flex', fontWeight:'bold', justifyContent:'end', padding:'10px'}}>
                                                            Read More ... 
                                                        </NavLink> 
                                                        </div>
                                                    </div>
                                                </div>
                                            ))): (servicesD.map((item, index) => (
                                                <div className=" m-b30" key={index}>
                                                    <div className="shimmer-effect">
                                                    <div className="shimmer shimmer-image" />
                                                    <div className="shimmer shimmer-text" />
                                                    {/* <div className="shimmer shimmer-text" style={{ width: '80%' }} /> */}
                                                    <div className="shimmer shimmer-icons" />
                                                </div>
                                                </div>
                                            )))
                                        }
                             </OwlCarousel>
                             </div>
                             </div>
                            </div>
                        </div>
                    </div>


                </div>
            </>
        );
    }
export default FeaturedServices;