import React from 'react';
import { NavLink } from 'react-router-dom';

var bnr1 = require('./../../images/banner/about_section_banner.jpg');

class Callus extends React.Component {

    render() {
        return (
            <>
                <div className="section-full mobile-page-padding p-tb80 overlay-wraper bg-cover bg-center" style={{backgroundImage:"url(" + bnr1 + ")"}}>
                    <div className="overlay-main bg-primary opacity-07" />
                    <div className="container">
                        <div className="section-content">
                            <div className="call-us-section text-center">
                                <h4 className="m-b15" style={{color:'white'}}>Let's work together</h4>
                                <h2 className="call-us-number m-b15 m-b0"> +251116395250</h2>
                                <h5 className="call-us-address m-t0 m-b20" style={{color:'white'}}>Addis Ababa, Sub City Bole, Imperial Werda 14 <br/> Alfoz Blaza Building & 2nd Office Wereda 13 Awi-Hakim Building</h5>
                                <NavLink to="/contactus" className="site-button-secondry btn-effect bg-dark">Contact Us</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Callus;