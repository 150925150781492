import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
const filters = [
    { label: "Educational Equipment", filter: ".cat-1" },
    { label: "Medical Devices", filter: ".cat-2" },
    { label: "Construction Materials", filter: ".cat-3" },
    { label: "Industrial Raw Materials", filter: ".cat-4" },
    { label: "Plastic Materials ", filter: ".cat-5" },
    { label: "Electrical Equipment", filter: ".cat-6" }
];


const products = [
    {
        image: require('./../../images/products/digital_micro.png'),
        title: 'Digital Microscopes',
        address: 'Addis Ababa, Ethiopia',
        width: 'col-md-3',
        filter: 'cat-1'
    },
    {
        image: require('./../../images/products/chemical_reagent.png'),
        title: 'Chemical Reagents',
        address: 'Bole, Ethiopia',
        width: 'col-md-3',
        filter: 'cat-1'
    },
    {
        image: require('./../../images/products/lab_glassware.png'),
        title: 'Laboratory Glassware',
        address: 'Addis Ababa, Ethiopia',
        width: 'col-md-3',
        filter: 'cat-1'
    },
    {
        image: require('./../../images/products/ultrasound_machine.png'),
        title: 'Portable Ultrasound Machines',
        address: 'Addis Ababa, Ethiopia',
        width: 'col-md-3',
        filter: 'cat-2'
    },
    {
        image: require('./../../images/products/patient_mon_syst.png'),
        title: 'Patient Monitoring Systems',
        address: 'Bole, Ethiopia',
        width: 'col-md-3',
        filter: 'cat-2'
    },
    {
        image: require('./../../images/products/surgical_inst.png'),
        title: 'Surgical Instrument Kits',
        address: 'Addis Ababa, Ethiopia',
        width: 'col-md-3',
        filter: 'cat-2'
    },
    {
        image: require('./../../images/products/concrete_mix.png'),
        title: 'High-Strength Concrete Mix',
        address: 'Addis Ababa, Ethiopia',
        width: 'col-md-3',
        filter: 'cat-3'
    },
    {
        image: require('./../../images/products/steel_bar.png'),
        title: 'Reinforcement Steel Bars',
        address: 'Bole, Ethiopia',
        width: 'col-md-6',
        filter: 'cat-3'
    },
    {
        image: require('./../../images/products/waterproof_membrane.png'),
        title: 'Waterproofing Membranes',
        address: 'Addis Ababa, Ethiopia',
        width: 'col-md-3',
        filter: 'cat-3'
    },
    {
        image: require('./../../images/products/polythylene.png'),
        title: 'Polyethylene Pellets',
        address: 'Bole, Ethiopia',
        width: 'col-md-3',
        filter: 'cat-4'
    },
    {
        image: require('./../../images/products/adhesive.png'),
        title: 'Industrial Adhesives',
        address: 'Addis Ababa, Ethiopia',
        width: 'col-md-3',
        filter: 'cat-4'
    },
    {
        image: require('./../../images/products/solvents.png'),
        title: 'Chemical Solvents',
        address: 'Bole, Ethiopia',
        width: 'col-md-3',
        filter: 'cat-4'
    },
    {
        image: require('./../../images/products/polypropylene.png'),
        title: 'Polypropylene Granules',
        address: 'Addis Ababa, Ethiopia',
        width: 'col-md-3',
        filter: 'cat-5'
    },
    {
        image: require('./../../images/products/pvc_resin.png'),
        title: 'PVC Resin',
        address: 'Bole, Ethiopia',
        width: 'col-md-3',
        filter: 'cat-5'
    },
    {
        image: require('./../../images/products/nylon_polymer.png'),
        title: 'Nylon Polymer Beads',
        address: 'Addis Ababa, Ethiopia',
        width: 'col-md-3',
        filter: 'cat-5'
    },
    {
        image: require('./../../images/products/breaker.png'),
        title: 'Circuit Breakers',
        address: 'Bole, Ethiopia',
        width: 'col-md-3',
        filter: 'cat-6'
    },
    {
        image: require('./../../images/products/transformer.png'),
        title: 'High-Voltage Transformers',
        address: 'Addis Ababa, Ethiopia',
        width: 'col-md-3',
        filter: 'cat-6'
    },
    {
        image: require('./../../images/products/led.png'),
        title: 'LED Lighting Systems',
        address: 'Bole, Ethiopia',
        width: 'col-md-3',
        filter: 'cat-6'
    }
];


const  OurProject = ({featured_product})=> {
    
   
    useEffect(()=>{
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');
    },[])

        const options = {
            loop:true,
            autoplay:false,
            center: false,
            items:3,
            margin:40,
            nav:true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                768:{
                    items:2
                },			
                991:{
                    items:3
                },
                1200:{
                    items:4
                }			
                
            }
        };
        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b30 square_shape2 ">
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="mt-separator-outer separator-left">
                                <div className="mt-separator">
                                    <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> Featured Products</h2>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content">
                            <div className="portfolio-wrap row mfp-gallery product-stamp clearfix">
                                {/* COLUMNS 1 */}
                                {/* <div className="stamp col-md-3 col-sm-6 m-b30">
                                    <div className="bg-gray p-a30">
                                        <div className="filter-wrap">
                                            <ul className="filter-navigation masonry-filter filterCustom">
                                                <li className="active"><NavLink to={"#"} data-filter="*" data-hover="All">All</NavLink></li>
                                                {filters.map((item, index) => (
                                                    <li key={index}><NavLink to={"#"} data-filter={item.filter} data-hover={item.label}>{item.label}</NavLink></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
                                {/* COLUMNS 2 */}
                                {/* {products.map((item, index) => (
                                   <div key={index} className={`${item.filter} masonry-item ${item.width} col-sm-6 m-b30`}>
                                        <div className="mt-box   image-hover-block">
                                            <div className="mt-thum-bx">
                                                <img src={item.image} alt=""/>
                                            </div>
                                            <div className="mt-info  p-t20 text-white">
                                                <h4 className="mt-tilte m-b10 m-t0">{item.title}</h4>
                                                <p className="m-b0">{item.address}</p>
                                            </div>
                                            <NavLink to="/project-detail"></NavLink>
                                        </div>
                                    </div>
                                ))} */}

                            
                                    <OwlCarousel className="owl-carousel work-carousel owl-btn-vertical-center" {...options}>
                                        {products.map((item, index) => (
                                            <div key={index} className="item mt-box">
                                                <div className="mt-img-effect mt-img-overlay7">
                                                    <img src={item.image}
                                                    style={{
                                                        height:'400px'
                                                    }}
                                                     alt="" />
                                                </div>
                                                <div className="mt-info p-a30">
                                                    <h4 className="m-b20 m-t0"><NavLink to={`/product/${item.slug}`}>{item.title}</NavLink></h4>
                                                    <p>{item.short_description}</p>
                                                    <NavLink to={`/product/${item.slug}`} className="site-button btn-effect">Read More</NavLink>
                                                </div>
                                            </div>
                                        ))}
                                    </OwlCarousel>
                          
                            </div>
                        </div>
                    </div>
                    <div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
                        <strong>Products</strong>
                    </div>
                </div>
            </>
        );
};

export default OurProject;