import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const defaultImages = [
    require('./../../images/logo/logo.png')
];

var bnr1 = require('./../../images/background/line.png');

const About4 = ({ about, imagesData }) => {
    const [aboutD, setAbout] = useState({});
    const [aboutImages, setAboutImages] = useState(defaultImages);

    useEffect(() => {
        function loadScript(src) {
            return new Promise((resolve, reject) => {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', () => resolve());
                script.addEventListener('error', (e) => reject(e));
                document.body.appendChild(script);
                document.body.removeChild(script);
            });
        }

        loadScript('./assets/js/masonary.js');

        // Check and log imagesData
        console.log("imagesData:", imagesData);

        if (imagesData) {
            try {
                const imagesD = imagesData;
                console.log("Parsed imagesData:", imagesD);
                setAboutImages(imagesD);
            } catch (error) {
                console.error("Failed to parse imagesData:", error);
            }
        }
    }, [imagesData]);

    const options = {
        loop: true,
        autoplay: true,
        autoplayTimeout: 3000,
        margin: 30,
        nav: false,
        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        items: 1,
        dots: true
    };

    console.log("aboutImages:", aboutImages);

    return (
        <>
            <div className="section-full p-t80 p-b80 bg-white inner-page-padding">
                <div className="container">
                    <div className="section-content">
                        <div className="row">
                            <div className="col-lg-5 col-md-5">

                                <div className="m-about">
                                    <OwlCarousel className="owl-carousel about-us-carousel" {...options}>
                                        {imagesData.length > 0 ? (
                                            imagesData.map((item, index) => (
                                                <div className="item" key={index}>


                                                    <div className="ow-img">
                                                        <NavLink to="/about">
                                                            <img
                                                                style={{ height: '380px' }}
                                                                src={ `${process.env.REACT_APP_API_URL}/storage/${item}`}
                                                                alt=""
                                                            />
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                                defaultImages.map((item, index) => (
                                            <div className="item">
                                                <div className="ow-img">

                                                        <NavLink to="/about" key={index} >
                                                            <img
                                                                style={{ height: '380px' }}
                                                                src={item}
                                                                alt=""
                                                            />
                                                        </NavLink>


                                                </div>
                                            </div>
                                                ))
                                        )}
                                    </OwlCarousel>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-7">
                                <div className="m-about-containt text-black p-t80">
                                    <div className="m-about-years bg-moving" style={{ backgroundImage: `url(${bnr1})` }}>
                                        <span className="text-primary large-title">
                                        {about.experience ? about.experience : 10}

                                        + </span>
                                        <span className="large-title-info">Years of Experience</span>
                                    </div>
                                    <h3 className="font-weight-600">Years of Expertise, Shaping Tomorrow's Solutions.</h3>
                                    <p>
                                        Over the years, we have expanded our services and product offerings, becoming a leader in
                                        the supply of laboratory equipment, medical devices, construction materials, and industrial
                                        raw materials.
                                    </p>
                                    <div className="author-info p-t20">
                                        <NavLink to="/contactus" className="site-button btn-effect m-b15">
                                            <span>Contact Us</span>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About4;
