import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {servicesData} from '../../data.js'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from 'axios';
const services = servicesData.slice(0,4);


var img1 = require('./../../images/background/bg-6.png');

const OurServices  = ({featured_service})=> {

    useEffect(()=>{
        function loadScript(src) {

            return new Promise(function(resolve, reject){
              var script = document.createElement('script');
              script.src = src;
              script.addEventListener('load', function () {
                resolve();
              });
              script.addEventListener('error', function (e) {
                reject(e);
              });
              document.body.appendChild(script);
              document.body.removeChild(script);
            })
          };

        loadScript('./assets/js/masonary.js');
    },[]);

    const options = {
        loop:true,
        autoplay:false,
        center: false,
        items:3,
        margin:40,
        nav:true,
        dots: false,
        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        responsive:{
            0:{
                items:1
            },
            768:{
                items:2
            },
            991:{
                items:3
            },
            1200:{
                items:4
            }

        }
    };


    const [featured, setFeatured] = useState(null);

    useEffect(() => {

        const fetchFeatured = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_featured`);
            setFeatured(response.data);
            console.log("Featured 234 : ",response.data);
        };


        fetchFeatured();
    }, []);


        const truncateText = (text) => {
            const maxLength = 150;
            if (text.length > maxLength) {
              return text.substring(0, maxLength) + '...';
            }
            return text;
          };

          const truncateTitle = (text) => {
            const maxLength = 40;
            if (text.length > maxLength) {
              return text.substring(0, maxLength) + '...';
            }
            return text;
          };

          console.log("Featured:", featured)
        return (
            <>
                <div className="section-full mobile-page-padding  p-b50  square_shape2">
                    <div className="section-content">
                        <div className="Service-half-top p-t80  bg-dark bg-moving" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                            <div className="container">
                                {/* TITLE START */}
                                <div className="section-head text-white">
                                    <div className="mt-separator-outer separator-left">
                                        <div className="mt-separator">
                                            <h2 className="text-white text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> Services</h2>
                                        </div>
                                    </div>
                                    <h3>Delivering Excellence and Innovation in Every Solution, Empowering Industries with Unmatched Quality and Service.</h3>
                                </div>
                                {/* TITLE END */}
                            </div>
                        </div>
                        <div className="services-half-bottom">
                            <div className="container">
                                <div className="row">
                                <OwlCarousel className="owl-carousel work-carousel owl-btn-vertical-center" {...options}>

                                    {featured && featured.service && featured.service.length>0 ?
                                    (featured.service.map((item, index) => (
                                        <div  key={index}>
                                            <div className="mt-icon-box-wraper m-b30">
                                                <div className="relative icon-count-2 bg-gray p-a30 ">
                                                    <span className="icon-count-number">{item.count}</span>
                                                    <div className=" inline-icon m-b15 text-primary scale-in-center">
                                                        <span className="icon-cell" >
                                                            <img
                                                            style={{width:'100%', height:'150px'}}
                                                            src={ `${process.env.REACT_APP_API_URL}/storage/${item.banner}` }

                                                            alt=""/>
                                                        </span>
                                                    </div>
                                                    <div className="icon-content">
                                                        <h5 className="m-b25">{truncateTitle(item.serviceName)}</h5>
                                                        <p dangerouslySetInnerHTML={{ __html: truncateText(item.description) }}></p>
                                                        <NavLink to={`/services/${item.slug}`} className="site-button-link" data-hover="Read More">Read More <i className="fa fa-angle-right arrow-animation" /></NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))):
                                    (services.map((item, index) => (
                                        <div className="col-md-3 col-sm-6" key={index}>
                                            <div className="mt-icon-box-wraper m-b30">
                                                <div className="shimmer-effect">
                                                    <div className="shimmer shimmer-image" />
                                                    <div className="shimmer shimmer-text" />
                                                    {/* <div className="shimmer shimmer-text" style={{ width: '80%' }} /> */}
                                                    <div className="shimmer shimmer-icons" />
                                                </div>
                                            </div>
                                        </div>
                                    )))
                                    }
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
                        <strong>Services</strong>
                    </div>
                </div>
            </>
        );
};

export default OurServices;