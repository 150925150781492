import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import SimilarProjects from './../Elements/SimilarProjects';
import Banner from './../Elements/Banner';
import ReactPlayer from 'react-player';
import {  servicesData } from '../../data';
import { useParams } from 'react-router-dom'; // Import useParams to get URL parameters
import SimilarProducts from '../Elements/SimilarProducts';
import Faq from '../Elements/Faq';
import OurValue3 from '../Elements/OurValueD';
import axios from 'axios'
import Loader from '../Elements/Loader';
const bnrimg = require('./../../images/banner/5.jpg');


const ServicesDetail = () => {

    const services = servicesData;
    const { slug } = useParams(); // Get the slug from URL
    const [service, setService] = useState(null);
    const [serviceImages, setServiceImages] = useState([]);
    const [about, setAbout] = useState({});

   
    useEffect(() => {
        const fetchService = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/service/${slug}`);
                setService(response.data);
                setServiceImages(JSON.parse(response.data.images));
            console.log("Service : ",response.data);
        };

        const fetchAbout = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/about_info`);
            setAbout(response.data);
            console.log("About Info : ",response.data);
        };
        fetchService();
        fetchAbout();

    }, [slug]);
  
    if (!service) {
        return <Loader/>; // Show loading state while fetching
    }  
    
    
    const options = {
        loop: true,
        autoplay: true,
        center: false,
        items: 3,
        margin: 40,
        nav: true,
        dots: false,
        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            991: {
                items: 3
            },
            1200: {
                items: 4
            }
        }
    };


    console.log("Service  2 : ", serviceImages);

    return (
        <>
            <Header />
            <div className="page-content">
            
                <Banner title={service.serviceName} pagename={service.serviceName} bgimage={ `${process.env.REACT_APP_API_URL}/storage/${service.banner}` } />

                {/* SECTION CONTENT START */}
                <div className="section-full p-tb80 inner-page-padding">
                    <div className="container">
                        <div className="project-detail-outer">
                           
                            <div className="m-b30">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="service-block">
                                            <div className="row">
                                                <div className="col-md-12 m-b10">
                                                    <h4 className="text-uppercase font-weight-600 m-b10">{service.serviceName}</h4>
                                                    <p dangerouslySetInnerHTML={{ __html: service.short_description }}>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="mt-box">
                                            <div className="mt-thum-bx mt-img-overlay1 mt-img-effect yt-thum-box">
                                                <img style={{height: '400px'}} src={ `${process.env.REACT_APP_API_URL}/storage/${service.banner}` } alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="project-detail-containt">
                                <div className="bg-white text-black">
                                    {/* <h3 className="font-weight-600">{ service.title}</h3> */}
                                    <p dangerouslySetInnerHTML={{ __html: service.description }}>
                                    </p>
                                </div>
                            </div>
                            <div className="m-b0">
                                <div className="row">
                                   {
                                    serviceImages.map((img, index) => (
                                     <div className="col-md-4 col-sm-4" key={index}>
                                        <div className="project-detail-pic m-b30">
                                            <div className="mt-media">
                                                <img src={ `${process.env.REACT_APP_API_URL}/storage/${img}` } alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                   {/* <Faq /> */}

                </div>
                <OurValue3 about={about}/>

                {/* SECTION CONTENT END */}
                {/* <SimilarProducts  products= {similarProducts}/> */}

            </div>
            <Footer />
        </>
    );
};

export default ServicesDetail;
