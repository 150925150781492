import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { productsData } from '../../data';

const products = productsData;

const bnr1 = require('./../../images/background/bg-6.png');

const SimilarProducts = ({products}) => {

    const [filteredProducts, setFilteredProducts] = useState(null)
    useEffect(() => {
        setFilteredProducts(products)
    }, [products]);

    const options = {
        loop: true,
        autoplay: true,
        center: false,
        items: 3,
        margin: 40,
        nav: true,
        dots: false,
        navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            991: {
                items: 3
            },
            1200: {
                items: 4
            }
        }
    };

    console.log(filteredProducts)
    return (
        <div className="section p-b50 bg-repeat square_shape2 bg-moving" >
            <div className="container">
                {/* TITLE START */}
                <div className="section-head">
                    <div className="mt-separator-outer separator-center">
                        <div className="mt-separator">
                            <h2 className="text-black sep-line-one "><span className="font-weight-600 text-primary">Similar</span> Products</h2>
                        </div>
                    </div>
                </div>
                {/* TITLE END */}
            </div>
            <div className="section-content container-fluid">
                <div className="similar-carousel-outer">
                    <OwlCarousel className="owl-carousel similar-products owl-btn-vertical-center" {...options}>
                        {filteredProducts && filteredProducts.map((item, index) => (
                                <div key={index} className={`${item.filter} masonry-item `}>
                                    <div className="image-effect-one hover-shadow">
                                        <img style={{ height: '260px' }} src={item.thumb} alt="" />
                                        <div className="figcaption" style={{padding:'10px !important'}}>
                                            <h4>{item.title}</h4>
                                            <p>{item.short_description.length > 50 ? item.short_description.substring(0, 50) + '...' : item.short_description}</p>
                                            <NavLink to={`/products/${item.slug}`} className={"text-primary button"} style={{display:'flex', fontWeight:'bold', justifyContent:'end', padding:'10px'}}>
                                                Read More ... 
                                            </NavLink>  
                                        </div>
                                        <a className="mfp-link" href={item.image}>
                                            <i className="fa fa-arrows-alt" />
                                        </a>
                                    </div>
                                </div>
                            ))}
                    </OwlCarousel>
                </div>
            </div>
        </div>
    );
};

export default SimilarProducts;
