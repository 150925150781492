import React, { useEffect, useState } from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import About4 from './../Elements/About4';
import Specialization4 from './../Elements/Specialization4';
import Team from './../Elements/Team';
import AboutSummary from './../Elements/AboutSummary';
import axios from 'axios';

var bnrimg = require('./../../images/about/banner.jpg');

const About = () => {
    const [about, setAbout] = useState({})
    const [teams, setTeams] = useState(null);
    const [aboutImage, setAboutImage] = useState([]);

    useEffect(() => {
        const fetchAbout = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/about_info`);
            setAbout(response.data);
            setAboutImage(JSON.parse(response.data.images));
            console.log("About Info : ",response.data);
        };


        const fetchTeams = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/teams-list`);
            setTeams(response.data);
            console.log("Team : ",response.data);
        };
        
        fetchAbout();
        fetchTeams();
    }, []);

        return (
            <>

                <Header />
                <div className="page-content">
                    <Banner title="Supplying Innovative Solutions for Education, Healthcare, and Infrastructure." pagename="About Us" bgimage={bnrimg}/>
                    <About4 about = {about} imagesData= {aboutImage}/>
                    <Specialization4 />
                    <Team teams ={teams ? teams : []}/>
                    <AboutSummary about={about}/>

                </div>

                <Footer />

            </>
        );
};

export default About;