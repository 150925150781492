import React, { useEffect, useState } from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import AboutCompany from '../Elements/AboutCompany';
import OurValue3 from '../Elements/OurValueD';
import { servicesData } from '../../data';
import FeaturedServices from '../Elements/FeaturedServices';
import AllServices from '../Elements/AllServices';
import axios from 'axios';
var bnrimg = require('./../../images/services/banner.jpg');

const Services = () =>{
    const servicesD = servicesData;


    const [featuredServices, setFeaturedServices] = useState([]);
    const [services, setServices] = useState({});
    const [about, setAbout] = useState({});
    useEffect(() => {
        const fetchService = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/service`);
            setServices(response.data);
            console.log("Service : ",response.data);

            const featuredServiceData = response.data.filter(p => p.is_featured ==1 );
            setFeaturedServices(featuredServiceData);
            console.log("Featured Serv : ",featuredServiceData);
        };

        const fetchAbout = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/about_info`);
            setAbout(response.data);
            console.log("About Info : ",response.data);
        };
        fetchAbout();
        fetchService();
    }, []);


    console.log(featuredServices)
        return (
            <>

                <Header />
                <div className="page-content">
                    <Banner title="Quality, Reliability, and Innovation in All Our Services." pagename="Services" bgimage={bnrimg}/>
                    <AboutCompany />
                    <FeaturedServices featured_services= {featuredServices}/>
                    <AllServices services={services} />
                    <OurValue3 about={about}/>
                <Footer />
                    
                </div>

            </>
        );
};

export default Services;