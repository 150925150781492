import React,{useEffect,useState} from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { AiFillTikTok } from 'react-icons/ai';
const teamMembers = [
    {
        image: require('./../../images/testimonials/abebe.png'),
        membername: 'Abebe Tesfaye',
        position: 'General Manager'
    },
    // {
    //    image: require('./../../images/testimonials/mulu.png'),
    //     membername: 'Mulu Alemayehu',
    //     position: 'HR'
    // },
    
    // {
    //     image: require('./../../images/testimonials/bekele.png'),
    //     membername: 'Bekele Tadesse',
    //     position: 'Accountant'
    // },
    // {
    //     image: require('./../../images/testimonials/abebe.png'),
    //     membername: 'Abebe Tesfaye',
    //     position: 'General Manager'
    // },
    // {
    //    image: require('./../../images/testimonials/mulu.png'),
    //     membername: 'Mulu Alemayehu',
    //     position: 'HR'
    // },
    
    // {
    //     image: require('./../../images/testimonials/bekele.png'),
    //     membername: 'Bekele Tadesse',
    //     position: 'Accountant'
    // },
    // {
    //    image: require('./../../images/testimonials/abebe.png'),
    //     membername: 'Gabriela Flores',
    //     position: 'Mechanical Engineer'
    // }
]

var bnr1 = require('./../../images/background/bg5.jpg');

const Team = ({teams}) =>{

            const options = {
                loop:true,
                autoplay:true,
                margin:30,
                nav:false,
                dots: true,
                navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
                responsive:{
                    0:{
                        items:1
                    },
                    991:{
                        items:4
                    }
                }
            };

            const [teamsData, setTeamsData] = useState(null);

            useEffect(()=>{
                function loadScript(src) {
                    return new Promise(function(resolve, reject){
                      var script = document.createElement('script');
                      script.src = src;
                      script.addEventListener('load', function () {
                        resolve();
                      });
                      script.addEventListener('error', function (e) {
                        reject(e);
                      });
                      document.body.appendChild(script);
                      document.body.removeChild(script);
                    })
                  };
          
                loadScript('./assets/js/masonary.js');
            },[]);
            useEffect(()=>{
                setTeamsData(teams);
            },[teams])
    
            console.log("P",teams)
        return (
            <>
                <div className="section-full p-t80 p-b50 square_shape1 bg-parallax bg-cover mobile-page-padding" data-stellar-background-ratio="0.5">
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head text-center text-black ">
                            <div className="mt-separator-outer separator-center">
                                <div className="mt-separator">
                                    <h2 className="text-black text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our </span> Team</h2>
                                </div>
                            </div>
                            {/* <h2>Discover Our Team</h2> */}
                        </div>
                        {/* TITLE END */}
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                            <OwlCarousel className="owl-carousel testimonial-home" {...options}>
                                {
                                    teams && teams.length>0 ?
                                    (teams.map((item, index) => (
                                            <div key={index} className="m-b30   ">
                                                <div className="our-team-2 ">
                                                    <div className="profile-image scale-in-center">
                                                    <img
                                                      src={`${process.env.REACT_APP_API_URL}/storage/${item.image}` } 
                                                      alt=""  
                                                      style={{height:'200px'}}
                                                      />
                                                    </div>
                                                    <div className="figcaption text-black">
                                                        <h4>{item.full_name}</h4>
                                                        <h5>{item.position}</h5>
                                                        <div className="icons">
                                                        {item.facebookLink && (
                                                        <a href={item.facebookLink} target="_blank" rel="noopener noreferrer">
                                                            <i className="fa fa-facebook" />
                                                        </a>
                                                        )}
                                                        {item.twitterLink && (
                                                        <a href={item.twitterLink} target="_blank" rel="noopener noreferrer">
                                                            <i className="fa fa-twitter" />
                                                        </a>
                                                        )}
                                                        {item.instagramLink && (
                                                        <a href={item.instagramLink} target="_blank" rel="noopener noreferrer">
                                                            <i className="fa fa-instagram" />
                                                        </a>
                                                        )}
                                                        {item.telegramLink && (
                                                        <a href={item.telegramLink} target="_blank" rel="noopener noreferrer">
                                                            <i className="fa fa-telegram" />
                                                        </a>
                                                        )}
                                                        {
                                                                item.linkedInLink &&
                                                                <a href={item.linkedInLink} target="_blank" rel="noopener noreferrer">
                                                                <i className="fa fa-linkedin" />
                                                                </a>
                                                            }

                                                            {
                                                                item.tiktokLink &&
                                                                <a href={item.tiktokLink} target="_blank" rel="noopener noreferrer">
                                                                <i  >
                                                                <AiFillTikTok  />
                                                                </i>
                                                                </a>
                                                            }

                                                        

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ):(
                                        teamMembers.map((item, index) => (
                                            <div key={index} className="m-b30   ">
                                                <div className="shimmer-effect">
                                                    <div className="shimmer shimmer-image" />
                                                    <div className="shimmer shimmer-text" />
                                                    <div className="shimmer shimmer-text" style={{ width: '80%' }} />
                                                    <div className="shimmer shimmer-icons" />
                                                </div>
                                            </div>
                                        ))
                                    )
                                }
                            </OwlCarousel>    
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
};

export default Team;