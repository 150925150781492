import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { productsData } from "../../data";
import axios from "axios";

var bnrimg = require("./../../images/banner/3.jpg");

const products = productsData;

const ProductSection = ({ featured_product }) => {
  useEffect(() => {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/masonary.js");
  }, []);

  const [featured, setFeatured] = useState(null);

  useEffect(() => {
    const fetchFeatured = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/get_featured`
      );
      setFeatured(response.data);
      console.log("Featured 2345: ", response.data);
    };

    fetchFeatured();
  }, []);
  const [featuredP, setFeaturedP] = useState([]);

  const options = {
    loop: true,
    autoplay: false,
    center: false,
    items: 3,
    margin: 40,
    nav: true,
    dots: false,
    navText: [
      '<i class="fa fa-angle-left"></i>',
      '<i class="fa fa-angle-right"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      991: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };
  useEffect(() => {
    setFeaturedP(featured_product);
  }, [featured_product]);

  console.log("Featured:", featured);

  const truncateText = (text) => {
    const maxLength = 60;
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const truncateTitle = (text) => {
    const maxLength = 20;
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <>
      {/* <Header /> */}
      <div className="page-content">
        {/* <Banner title="Broad vision. Careful thought. Hand-crafted design." pagename="Project carousel" bgimage={bnrimg}/> */}

        {/* SECTION CONTENT START */}
        <div className="section-full p-tb80 bg-gray inner-page-padding">
          <div className="container-fluid">
            <div className="section-head">
              <div className="mt-separator-outer separator-left">
                <div className="mt-separator">
                  <h2 className="text-uppercase sep-line-one ">
                    <span className="font-weight-300 text-primary">Our</span>{" "}
                    Featured Products
                  </h2>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="work-carousel-outer">
                <OwlCarousel
                  className="owl-carousel work-carousel owl-btn-vertical-center"
                  {...options}
                >
                  {/* {
                    featured &&  featured.product  && featured.product.length>0 ?
                    (
                        featured.product.map((item, index) => (
                        <div key={index} className="item mt-box">
                            <div className="mt-img-effect mt-img-overlay7">
                                <img 
                                src={ `${process.env.REACT_APP_API_URL}/storage/${item.banner}` }
                                style={{height:'250px'}} alt="" />
                            </div>
                            <div className="mt-info p-a30">
                                <h4 className="m-b20 m-t0"><NavLink to={`/products/${item.slug}`}>{truncateTitle(item.title)}</NavLink></h4>
                                <p dangerouslySetInnerHTML={{ __html: truncateText(item.description) }}></p>
                            </div>
                        </div>
                    ))
                    ):(
                        products.map((item, index) => (
                        <div key={index} className="item mt-box">
                            <div className="shimmer-effect">
                                <div className="shimmer shimmer-image" />
                                <div className="shimmer shimmer-text" />
                                <div className="shimmer shimmer-text" style={{ width: '80%' }} />
                                <div className="shimmer shimmer-icons" />
                            </div>
                        </div>
                    ))
                    )
                } */}

                  {featured && featured.product && featured.product.length > 0
                    ? featured.product.map((item, index) => (
                        <div
                          style={{
                            padding: "0px 5px",
                          }}
                          key={index}
                          className={`${item.product_category_id} m-b30`}
                        >
                          <div
                            style={{
                              boxShadow: "0px 0px 5px",
                              overflow: "hidden",
                              position: "relative",
                            }}
                            className="product-card"
                          >
                            <div className="image-container">
                              <img
                                className="product-image"
                                style={{
                                  width: "100%",
                                  height: "200px",
                                  transition: "transform 0.3s ease-in-out",
                                }}
                                src={`${process.env.REACT_APP_API_URL}/storage/${item.banner}`}
                                alt=""
                              />
                            </div>
                            <div
                              className="p-4"
                              style={{ padding: "10px !important" }}
                            >
                              <h4 style={{ padding: "5px 5px 0px 5px" }}>
                                {item.title.length > 25
                                  ? item.title.substring(0, 25) + "..."
                                  : item.title}
                              </h4>
                              <NavLink
                                to={`/products/${item.slug}`}
                                className={"text-primary button"}
                                style={{
                                  display: "flex",
                                  fontWeight: "bold",
                                  justifyContent: "end",
                                  padding: "10px",
                                }}
                              >
                                Read More ...
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      ))
                    : products.map((item, index) => (
                        <div
                          key={index}
                          className="col-md-4 col-sm-6"
                          style={{ padding: "2px" }}
                        >
                          <div className="shimmer-effect">
                            <div className="shimmer shimmer-image" />
                            <div className="shimmer shimmer-text" />
                            <div
                              className="shimmer shimmer-text"
                              style={{ width: "80%" }}
                            />
                            <div className="shimmer shimmer-icons" />
                          </div>
                        </div>
                      ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top p-t30">
            <strong>Products</strong>
          </div>
        </div>
        {/* SECTION CONTENT END  */}
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default ProductSection;
