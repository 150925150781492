export const productsData = [
    {
        id: 1,
        thumb: require('./images/products/digital_micro.png'),
        image: require('./images/products/digital_micro.png'),
        title: 'Digital Microscopes',
        description: `Our high-precision digital microscopes offer unparalleled clarity and detail for examination and analysis. These advanced instruments are designed to meet the rigorous demands of both research and industrial applications. Equipped with cutting-edge imaging technology, our digital microscopes provide crisp, high-resolution images that enable users to explore minute details with ease. 

        The intuitive interface and robust build ensure reliable performance in various settings, from laboratory environments to field studies. In addition to their exceptional imaging capabilities, our digital microscopes come with a range of features to enhance usability and versatility.

        With adjustable magnification levels, integrated measurement tools, and compatibility with various software programs, they offer a comprehensive solution for professionals seeking accuracy and efficiency. Whether you're conducting scientific research, quality control inspections, or educational demonstrations, our digital microscopes deliver outstanding results and value.`,
        short_description: `Our high-precision digital microscopes offer unparalleled clarity and detail for examination and analysis.\n\nThese advanced instruments are designed to meet the rigorous demands of both research and industrial applications.`,
        filter: 'cat-1',
        slug: 'digital-microscopes'
    },
    {
        id: 2,
        thumb: require('./images/products/chemical_reagent.png'),
        image: require('./images/products/chemical_reagent.png'),
        title: 'Chemical Reagents',
        description: `Our chemical reagents are meticulously formulated to ensure precise and reliable laboratory results. Designed to meet the highest standards of quality, they are essential for a wide range of analytical and experimental procedures.

        Each reagent undergoes rigorous testing to guarantee purity and consistency, making them suitable for both routine and specialized applications. The high-quality components of our reagents contribute to accurate measurements and dependable outcomes.

        Whether used in research laboratories or industrial settings, these chemical reagents provide the reliability and performance needed for successful experiments and analyses.`,
        short_description: `High-quality chemical reagents for accurate and reliable laboratory results.\n\nFormulated to meet the highest standards for various analytical procedures.`,
        filter: 'cat-1',
        slug: 'chemical-reagents'
    },
    {
        id: 3,
        thumb: require('./images/products/lab_glassware.png'),
        image: require('./images/products/lab_glassware.png'),
        title: 'Laboratory Glassware',
        description: `Our laboratory glassware is crafted for durability and precision, making it an essential component of any scientific setup. Designed to withstand various chemical reactions and temperatures, this glassware ensures reliable performance in all laboratory tasks.

        Each piece of glassware is manufactured with high-quality materials, offering clarity and resistance to breakage. This ensures that your experiments and procedures are conducted with accuracy and safety.

        From beakers and flasks to test tubes and pipettes, our range of glassware meets the diverse needs of scientific research, quality control, and educational demonstrations.`,
        short_description: `Durable and precise laboratory glassware for all your scientific needs.\n\nCrafted to withstand various chemical reactions and temperatures.`,
        filter: 'cat-1',
        slug: 'laboratory-glassware'
    },
    {
        id: 4,
        thumb: require('./images/products/ultrasound_machine.png'),
        image: require('./images/products/ultrasound_machine.png'),
        title: 'Portable Ultrasound Machines',
        description: `Our portable ultrasound machines combine compact design with advanced imaging technology for efficient diagnostics. Ideal for on-the-go use, these machines provide high-quality imaging in a variety of settings.

        The portable nature of these machines makes them perfect for mobile clinics, emergency situations, and field assessments. They are designed to deliver accurate results quickly, enhancing the diagnostic capabilities of healthcare professionals.

        Featuring user-friendly interfaces and robust performance, our portable ultrasound machines ensure reliable and effective diagnostics wherever needed.`,
        short_description: `Compact and portable ultrasound machines for quick and easy diagnostics.\n\nProvides high-quality imaging in various settings for efficient healthcare assessments.`,
        filter: 'cat-2',
        slug: 'portable-ultrasound-machines'
    },
    {
        id: 5,
        thumb: require('./images/products/patient_mon_syst.png'),
        image: require('./images/products/patient_mon_syst.png'),
        title: 'Patient Monitoring Systems',
        description: `Our patient monitoring systems are designed to provide comprehensive care and ensure patient safety. Equipped with advanced features, these systems offer real-time monitoring of vital signs and other critical parameters.

        The reliability and accuracy of our monitoring systems make them essential for both acute and chronic care settings. They are engineered to deliver consistent performance and integrate seamlessly with existing healthcare infrastructure.

        With intuitive interfaces and customizable settings, our patient monitoring systems support healthcare professionals in delivering high-quality care and improving patient outcomes.`,
        short_description: `Advanced patient monitoring systems to ensure comprehensive care and safety.\n\nProvides real-time monitoring of vital signs and critical parameters.`,
        filter: 'cat-2',
        slug: 'patient-monitoring-systems'
    },
    {
        id: 6,
        thumb: require('./images/products/surgical_inst.png'),
        image: require('./images/products/surgical_inst.png'),
        title: 'Surgical Instrument Kits',
        description: `Our surgical instrument kits are meticulously designed for precision and reliability in the operating room. Each kit includes a comprehensive selection of instruments needed for various surgical procedures.

        The high-quality construction of our surgical instruments ensures durability and performance, meeting the stringent requirements of modern surgical practices. They are crafted to facilitate ease of use and maintain the highest standards of hygiene.

        Ideal for hospitals, clinics, and surgical centers, our kits support successful surgeries and contribute to positive patient outcomes.`,
        short_description: `Complete surgical instrument kits designed for precision and reliability in the operating room.\n\nIncludes a comprehensive selection of instruments for various procedures.`,
        filter: 'cat-2',
        slug: 'surgical-instrument-kits'
    },
    {
        id: 7,
        thumb: require('./images/products/concrete_mix.png'),
        image: require('./images/products/concrete_mix.png'),
        title: 'High-Strength Concrete Mix',
        description: `Our high-strength concrete mix is formulated for robust and durable construction projects. Designed to withstand heavy loads and adverse conditions, this concrete mix is ideal for structural applications.

        The premium quality of our concrete mix ensures exceptional performance and longevity, making it suitable for a wide range of construction needs. Its superior strength and reliability make it a preferred choice for both residential and commercial projects.

        Whether used for foundations, pavements, or other structural elements, our concrete mix provides the durability and strength required for successful construction.`,
        short_description: `Premium high-strength concrete mix for robust construction projects.\n\nEnsures exceptional performance and durability for various structural applications.`,
        filter: 'cat-3',
        slug: 'high-strength-concrete-mix'
    },
    {
        id: 8,
        thumb: require('./images/products/steel_bar.png'),
        image: require('./images/products/steel_bar.png'),
        title: 'Reinforcement Steel Bars',
        description: `Our reinforcement steel bars are designed to provide structural integrity and durability in construction projects. Manufactured to meet high standards, these steel bars are essential for reinforcing concrete structures.

        The high-quality steel used in our bars ensures strength and resistance to various environmental factors, contributing to the longevity of construction projects. They are available in various sizes and grades to meet diverse construction requirements.

        Ideal for use in buildings, bridges, and other infrastructure, our reinforcement steel bars support the structural stability and safety of construction endeavors.`,
        short_description: `High-quality reinforcement steel bars for structural integrity and durability.\n\nProvides strength and resistance for various construction applications.`,
        filter: 'cat-3',
        slug: 'reinforcement-steel-bars'
    },
    {
        id: 9,
        thumb: require('./images/products/waterproof_membrane.png'),
        image: require('./images/products/waterproof_membrane.png'),
        title: 'Waterproofing Membranes',
        description: `Our waterproofing membranes offer effective protection against water damage for various structures. Designed for durability and reliability, these membranes are ideal for use in both residential and commercial applications.

        The advanced materials used in our membranes ensure resistance to moisture and environmental factors, extending the lifespan of protected structures. They are easy to install and provide long-lasting protection against leaks and water infiltration.

        Suitable for use in roofs, foundations, and basements, our waterproofing membranes contribute to the overall integrity and durability of construction projects.`,
        short_description: `Effective waterproofing membranes to protect structures from water damage.\n\nProvides long-lasting protection against leaks and moisture infiltration.`,
        filter: 'cat-3',
        slug: 'waterproofing-membranes'
    },
    {
        id: 10,
        thumb: require('./images/products/polythylene.png'),
        image: require('./images/products/polythylene.png'),
        title: 'Polyethylene Pellets',
        description: `Our polyethylene pellets are of high quality, suitable for various industrial applications. Known for their durability and versatility, these pellets are used in a wide range of manufacturing processes.

        The consistent quality of our pellets ensures reliable performance and efficiency in production. They are available in different grades and sizes to meet specific industrial needs.

        Ideal for use in packaging, molding, and extrusion processes, our polyethylene pellets offer excellent performance and value.`,
        short_description: `High-quality polyethylene pellets for various industrial applications.\n\nKnown for their durability and versatility in manufacturing processes.`,
        filter: 'cat-4',
        slug: 'polyethylene-pellets'
    },
    {
        id: 11,
        thumb: require('./images/products/adhesive.png'),
        image: require('./images/products/adhesive.png'),
        title: 'Industrial Adhesives',
        description: `Our industrial adhesives are designed for strong and reliable bonding in a wide range of applications. These adhesives offer excellent adhesion and durability, making them suitable for various industrial and manufacturing processes.

        The high-performance formulation ensures that our adhesives can withstand different environmental conditions and stresses. They are available in various types to meet specific bonding requirements.

        Whether used for assembly, repairs, or other industrial tasks, our adhesives provide dependable results and long-lasting performance.`,
        short_description: `Strong and reliable industrial adhesives for a wide range of uses.\n\nOffers excellent adhesion and durability for various manufacturing processes.`,
        filter: 'cat-4',
        slug: 'industrial-adhesives'
    },
    {
        id: 12,
        thumb: require('./images/products/solvents.png'),
        image: require('./images/products/solvents.png'),
        title: 'Chemical Solvents',
        description: `Our chemical solvents are versatile and suitable for various industrial processes. Designed to meet high standards of quality, they offer effective solutions for cleaning, extraction, and other chemical applications.

        The purity and consistency of our solvents ensure reliable performance in diverse settings. They are formulated to meet the specific needs of different industrial processes, providing efficient results.

        Ideal for use in laboratories, manufacturing, and other industrial environments, our chemical solvents deliver dependable and high-quality performance.`,
        short_description: `Versatile chemical solvents suitable for various industrial processes.\n\nOffers effective solutions for cleaning, extraction, and other applications.`,
        filter: 'cat-4',
        slug: 'chemical-solvents'
    },
    {
        id: 13,
        thumb: require('./images/products/polypropylene.png'),
        image: require('./images/products/polypropylene.png'),
        title: 'Polypropylene Granules',
        description: `Our polypropylene granules are durable and suitable for various manufacturing and production applications. These granules are known for their strength and versatility in different industrial processes.

        The high-quality formulation of our polypropylene granules ensures consistent performance and reliability. They are ideal for use in injection molding, extrusion, and other production methods.

        Whether used in packaging, automotive, or consumer goods, our polypropylene granules offer excellent quality and value.`,
        short_description: `Durable polypropylene granules for manufacturing and production.\n\nKnown for their strength and versatility in industrial processes.`,
        filter: 'cat-5',
        slug: 'polypropylene-granules'
    },
    {
        id: 14,
        thumb: require('./images/products/pvc_resin.png'),
        image: require('./images/products/pvc_resin.png'),
        title: 'PVC Resin',
        description: `Our PVC resin is of high quality, suitable for versatile industrial applications. This resin is used in the production of various PVC products, offering excellent performance and durability.

        The formulation of our PVC resin ensures consistency and reliability in manufacturing processes. It is ideal for use in pipes, fittings, and other PVC components.

        With its superior quality and versatility, our PVC resin provides a dependable solution for numerous industrial and commercial applications.`,
        short_description: `High-quality PVC resin for versatile industrial applications.\n\nOffers excellent performance and durability for various PVC products.`,
        filter: 'cat-5',
        slug: 'pvc-resin'
    },
    {
        id: 15,
        thumb: require('./images/products/nylon_polymer.png'),
        image: require('./images/products/nylon_polymer.png'),
        title: 'Nylon Polymer Beads',
        description: `Our nylon polymer beads are robust and ideal for a variety of uses. These beads are known for their durability and performance in different industrial applications.

        The high-quality formulation of our nylon polymer beads ensures reliability and consistency. They are suitable for use in manufacturing, production, and other industrial processes.

        Whether used in textiles, automotive parts, or consumer goods, our nylon polymer beads provide excellent performance and value.`,
        short_description: `Robust nylon polymer beads ideal for a variety of uses.\n\nKnown for their durability and performance in industrial applications.`,
        filter: 'cat-5',
        slug: 'nylon-polymer-beads'
    },
    {
        id: 16,
        thumb: require('./images/products/breaker.png'),
        image: require('./images/products/breaker.png'),
        title: 'Circuit Breakers',
        description: `Our circuit breakers are designed to ensure electrical safety and protection. They offer reliable performance in safeguarding electrical systems from overloads and short circuits.

        The high-quality construction of our circuit breakers ensures durability and dependable operation. They are suitable for various applications, including residential, commercial, and industrial settings.

        With advanced features and compliance with safety standards, our circuit breakers provide effective protection and peace of mind.`,
        short_description: `Reliable circuit breakers to ensure electrical safety and protection.\n\nOffers dependable performance in safeguarding electrical systems.`,
        filter: 'cat-6',
        slug: 'circuit-breakers'
    },
    {
        id: 17,
        thumb: require('./images/products/transformer.png'),
        image: require('./images/products/transformer.png'),
        title: 'High-Voltage Transformers',
        description: `Our high-voltage transformers are efficient and designed for power distribution and management. They ensure stable and reliable electrical supply for various applications.

        Built with advanced technology, these transformers provide optimal performance and durability. They are suitable for use in power plants, substations, and other high-voltage environments.

        The high-quality construction and design of our transformers support effective power management and contribute to the reliability of electrical systems.`,
        short_description: `Efficient high-voltage transformers for power distribution and management.\n\nProvides stable and reliable electrical supply for various applications.`,
        filter: 'cat-6',
        slug: 'high-voltage-transformers'
    },
    {
        id: 18,
        thumb: require('./images/products/led.png'),
        image: require('./images/products/led.png'),
        title: 'LED Lighting Systems',
        description: `Our LED lighting systems are energy-efficient and suitable for a wide range of applications. They offer bright and clear illumination while minimizing energy consumption.

        Designed for longevity and performance, these LED systems are ideal for both indoor and outdoor use. They provide excellent light quality and are available in various styles and configurations.

        Whether used in residential, commercial, or industrial settings, our LED lighting systems contribute to cost savings and enhanced visibility.`,
        short_description: `Energy-efficient LED lighting systems for various applications.\n\nOffers bright illumination with minimal energy consumption.`,
        filter: 'cat-6',
        slug: 'led-lighting-systems'
    }
];
export const servicesData = [
    {
        id: 1,
        thumb: require('./images/products/lab_glassware.png'),
        image: require('./images/products/lab_glassware.png'),
        title: 'Laboratory Equipment Supply',
        description: `We provide a comprehensive range of laboratory equipment designed to meet the diverse needs of educational institutions, research facilities, and healthcare providers. From microscopes to advanced diagnostic tools, our equipment ensures accuracy, reliability, and durability. Our team ensures that each product meets the highest quality standards, making us a trusted partner in the scientific and medical communities.`,
        short_description: `Comprehensive range of high-quality laboratory equipment for educational, research, and healthcare institutions.`,
        filter: 'cat-1',
        slug: 'laboratory-equipment-supply',
        featured: true
    },
    {
        id: 2,
        thumb: require('./images/products/ultrasound_machine.png'),
        image: require('./images/products/ultrasound_machine.png'),
        title: 'Medical Devices Supply',
        description: `Our medical devices supply service offers advanced, reliable, and cost-effective solutions for healthcare facilities. We supply devices for diagnostics, treatment, and patient care, ensuring that healthcare professionals have access to the latest technology. Our product range includes everything from portable ultrasound machines to patient monitoring systems, making us a key partner in improving healthcare outcomes.`,
        short_description: `Advanced medical devices supply for diagnostics, treatment, and patient care.`,
        filter: 'cat-2',
        slug: 'medical-devices-supply',
        featured: true
    },
    {
        id: 3,
        thumb: require('./images/products/transformer.png'),
        image: require('./images/products/transformer.png'),
        title: 'Construction Materials Supply',
        description: `We provide high-quality construction materials, including reinforcement steel bars, concrete mixes, and waterproofing membranes. Our products are designed to meet the rigorous demands of the construction industry, ensuring durability, reliability, and compliance with industry standards. Whether for residential, commercial, or industrial projects, our materials deliver outstanding performance.`,
        short_description: `Durable and reliable construction materials supply for various projects.`,
        filter: 'cat-3',
        slug: 'construction-materials-supply',
        featured: false
    },
    {
        id: 4,
        thumb: require('./images/products/solvents.png'),
        image: require('./images/products/solvents.png'),
        title: 'Chemical & Industrial Supplies',
        description: `We offer a wide range of chemical and industrial supplies, including chemical solvents, polyethylene pellets, and industrial adhesives. Our products are designed to support various industrial processes, from manufacturing to packaging and beyond. With a focus on quality and performance, our supplies help ensure efficient and effective operations.`,
        short_description: `Wide range of chemical and industrial supplies for diverse applications.`,
        filter: 'cat-4',
        slug: 'chemical-industrial-supplies',
        featured: false
    },
    {
        id: 5,
        thumb: require('./images/products/breaker.png'),
        image: require('./images/products/breaker.png'),
        title: 'Plastic Raw Materials & Chemicals',
        description: `We provide a variety of plastic raw materials and chemicals essential for industrial applications. From polyethylene pellets to solvents and industrial adhesives, we offer products that support the manufacturing sector with high-performance materials.`,
        short_description: `Essential plastic raw materials and chemicals for industrial processes.`,
        filter: 'cat-5',
        slug: 'plastic-raw-materials-chemicals',
        featured: false
    },
    {
        id: 6,
        thumb: require('./images/products/pvc_resin.png'),
        image: require('./images/products/pvc_resin.png'),
        title: 'Tire, Battery, & Spare Parts Supply',
        description: `We supply a wide range of tires, batteries, and spare parts for various vehicles. Our products ensure optimal performance and longevity for automobiles, meeting the demands of the automotive industry.`,
        short_description: `Comprehensive supply of tires, batteries, and spare parts for vehicles.`,
        filter: 'cat-6',
        slug: 'tire-battery-spare-parts',
        featured: true
    },
    {
        id: 7,
        thumb: require('./images/products/breaker.png'),
        image: require('./images/products/breaker.png'),
        title: 'Electrical Equipment Supply',
        description: `We offer a broad range of electrical equipment, including wiring, switches, and industrial-grade transformers. Our products are suitable for both commercial and residential electrical installations, ensuring safety and performance.`,
        short_description: `High-quality electrical equipment for commercial and residential installations.`,
        filter: 'cat-7',
        slug: 'electrical-equipment-supply',
        featured: false
    },
    {
        id: 8,
        thumb: require('.//images/products/transformer.png'),
        image: require('.//images/products/transformer.png'),
        title: 'General Contractors GC-5',
        description: `As a certified General Contractor (GC-5), we handle diverse construction projects with precision and quality. Our services include planning, design, and execution of construction works, adhering to the highest industry standards.`,
        short_description: `Certified General Contractor (GC-5) for comprehensive construction services.`,
        filter: 'cat-8',
        slug: 'general-contractors-gc5',
        featured: false
    },
    {
        id: 9,
        thumb: require('./images/products/nylon_polymer.png'),
        image: require('./images/products/nylon_polymer.png'),
        title: 'Geo-technical & Foundation Investigation',
        description: `We specialize in geo-technical and foundation investigation services, providing reliable data and insights for construction projects. Our laboratory testing ensures that foundation designs are safe and efficient for any type of building or infrastructure.`,
        short_description: `Expert geo-technical and foundation investigation services with advanced laboratory testing.`,
        filter: 'cat-9',
        slug: 'geo-technical-foundation-investigation',
        featured: false
    }
];



export const filtersData = [
    { label: "Educational Equipment", filter: ".cat-1" },
    { label: "Medical Devices", filter: ".cat-2" },
    { label: "Construction Materials", filter: ".cat-3" },
    { label: "Industrial Raw Materials", filter: ".cat-4" },
    { label: "Plastic Materials", filter: ".cat-5" },
    { label: "Electrical Equipment", filter: ".cat-6" }
];
