import React from 'react';
import { NavLink } from 'react-router-dom';

class AboutCompany extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 bg-white">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                    <div className="col-md-5 col-sm-12">
                                        <div className="service-about-left">
                                            <div className="mt-media">
                                                <img src={require('./../../images/services/hero.jpg')} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-12">
                                        <div className="service-about-right m-b30">
                                            <h3 className="m-t0">  <span className='text-primary'>
                                                Our Expertise, Your Success 
                                            </span><br/> Comprehensive Solutions for Every Sector</h3>
                                            <p>
                                                At Laffo Engineering PLC, we offer a comprehensive range of services designed to meet the diverse needs of our clients across various industries. With a commitment to quality and innovation, we deliver tailored solutions that drive progress and success. Whether you're in education, healthcare, construction, or industrial sectors, our expertise and high-quality products ensure that your projects achieve their highest potential.                                           
                                            </p>
                                            <div className="call-for-quote-outer">
                                                <div className="call-quote">
                                                    <h4>+251 944 257 225 <br/>
                                                    +251 116 395 250 <br/>
                                                    +251 911 168 287</h4>
                                                </div>
                                                <div className="call-estimate bg-dark">
                                                <NavLink to={"/about"} className="site-button btn-effect">Contact for More Information</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default AboutCompany;