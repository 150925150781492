import React from 'react';
import { NavLink } from 'react-router-dom';

var bnr1 = require('./../../images/background/bg-5.png');
const services = [
    {
        count: 1,
        title: `Commitment to Quality`,
        icon: require('./../../images/icon/compass.png'),
        image: require('./../../images/home/banner2.jpg'),
        description: 'We ensure excellence in all our products and services through rigorous quality control and reliable partnerships.',
    },
    {
        count: 2,
        title: 'Customer-Centric Approach',
        icon: require('./../../images/icon/crane-1.png'),
        image: require('./../../images/home/banner4.jpg'),
        description: ' Our focus is on delivering tailored solutions and exceptional service to meet and exceed our clients needs.',
    },
    {
        count: 3,
        title: 'Integrity and Reliability',
        icon: require('./../../images/icon/renovation.png'),
        image: require('./../../images/home/banner3.jpg'),
        description: 'We uphold honesty and transparency in all our dealings, consistently delivering on our promises with professionalism.',
    }
]

class Specialization extends React.Component {
    render() {
        return (
            <>
                <div className="section-full mobile-page-padding bg-white p-t80 p-b30 bg-repeat square_shape1" style={{backgroundImage:"url(" + bnr1 + ")"}}>
                    <div className="container">
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                                {services.map((item, index) => (
                                    <div className="col-lg-3 col-md-12 col-sm-6 m-b30" key={index}>
                                        <div className="half-blocks">
                                            <div className="mt-icon-box-wraper  m-b30">
                                                <div className="half-block-content icon-count-2 p-a30 p-tb50" style={{paddingBottom: '30px !important'}}>
                                                    <span className="icon-count-number">{item.count}</span>
                                                    <div className="mt-icon-box-lg radius bg-gray m-b20 scale-in-center">
                                                        <span className="icon-cell"><img src={item.icon} alt=""/></span>
                                                    </div>
                                                    <div className="icon-content">
                                                        <h4 className="mt-tilte m-b25">{item.title}</h4>
                                                        <p>{item.description}</p>
                                                        {/* <NavLink to={"/services"} className="site-button-link" data-hover="Read More">Read More <i className="fa fa-angle-right arrow-animation" /></NavLink> */}
                                                    </div>
                                                </div>
                                                <div className="bg-cover  half-blocks-bg" style={{ backgroundImage: 'url(' + item.image + ')' }} />
                                            </div>
                                        </div>
                                    </div>

                                    ))}


                                <div className="col-lg-3 col-md-12 col-sm-6 m-b30">
                                    <div className="mt-box our-speciallization-content">
                                        <h3 className="m-t0"><span className="font-weight-100">Laffo</span> <br />Engineering.</h3>
                                        <p>
                                        Your premier source for high-quality educational laboratory equipment, medical devices, construction materials, plastic raw materials, and chemicals                                        </p>
                                        <NavLink to="/about"  className="site-button btn-effect">
                                            Learn More
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="hilite-title text-right p-r50 text-uppercase text-pop-up-top">
                        <strong>Why Us ?</strong>
                    </div>
                </div>
            </>
        );
    }
};

export default Specialization;