import React, { useEffect, useState } from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Slider from './../Elements/Slider';
import Specialization from './../Elements/Specialization';
import About from './../Elements/About';
import OurValue from './../Elements/OurValue';
import OurMission from './../Elements/OurMission';
import OurServices from './../Elements/OurServices';
import Callus from './../Elements/Callus';
import OurProject from './../Elements/OurProject';
import Blogs from './../Elements/Blogs';
import Testimonials from './../Elements/Testimonials';
import axios from 'axios';
import ProjectCarousel from './ProjectCarousel';
import ProductSection from './ProductSection';

const Home =()=> {




    const [about, setAbout] = useState({});
    const [featured, setFeatured] = useState(null);
    const [aboutImages, setAboutImages] = useState([]);
    const [partners, setPartners] = useState(null);

    useEffect(() => {
        const fetchAbout = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/about_info`);
            setAbout(response.data);
            console.log("About Info : ",response.data);
            setAboutImages(JSON.parse(response.data.images));
        };


        const fetchFeatured = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/get_featured`);
            setFeatured(response.data);
            console.log("Featured : ",response.data);
        };


        const fetchPartners = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/partners-list`);
            setPartners(response.data);
            console.log("Partners : ",response.data);
        };

        fetchAbout();
        fetchFeatured();
        fetchPartners();
    }, []);


        return (
            <>
                <Header />
                <div className="page-content">
                    <Slider />
                    <Specialization />
                    <About imagesData={aboutImages}/>
                    <OurValue about ={about}/>
                    <OurMission  about ={about}/>
                    <OurServices featured_service = {featured && featured.service?featured.service:[]}/>
                    <Callus />
                    <ProductSection featured_product = {featured && featured.product?featured.product:[]}/>
                    {/* <OurProject /> */}
                    {/* <Blogs /> */}
                    <Testimonials partners={partners ? partners : []}/>
                </div>
                <Footer />
            </>
        );
};

export default Home;