import React from 'react';
import { NavLink } from 'react-router-dom';

const services = [
    {
        count: 1,
        title: `Commitment to Quality`,
        icon: require('./../../images/icon/compass.png'),
        image: require('./../../images/services/1.jpg'),
        description: 'We ensure excellence in all our products and services through rigorous quality control and reliable partnerships.',
    },
    {
        count: 2,
        title: 'Customer-Centric Approach',
        icon: require('./../../images/icon/crane-1.png'),
        image: require('./../../images/services/3.jpg'),
        description: 'Our focus is on delivering tailored solutions and exceptional service to meet and exceed our clients needs.',
    },
    {
        count: 3,
        title: 'Integrity and Reliability',
        icon: require('./../../images/icon/renovation.png'),
        image: require('./../../images/services/2.jpg'),
        description: 'We uphold honesty and transparency in all our dealings, consistently delivering on our promises with professionalism.',
    },
    {
        count: 4,
        title: 'Innovative Solutions',
        icon: require('./../../images/icon/compass.png'), // add a relevant icon for innovation
        image: require('./../../images/services/2.jpg'),
        description: 'We continuously seek out new technologies and methodologies to bring cutting-edge solutions to our clients.',
    }
];


var img1 = require('./../../images/background/ptn-1.png');

class Specialization4 extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-tb80 bg-white bg-repeat square_shape2 inner-page-padding" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="mt-separator-outer separator-left">
                                <div className="mt-separator">
                                    <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> Speciallization</h2>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        {/* IMAGE CAROUSEL START */}
                        <div className="section-content">
                            <div className="row">
                                    {services.map((item, index) => (
                                        <div className="col-md-3 col-sm-6" key={index}>
                                            <div className="mt-icon-box-wraper m-b30">
                                                <div className="relative icon-count-2 bg-gray p-a30 p-tb50">
                                                    <span className="icon-count-number">{item.count}</span>
                                                    <div className="icon-md inline-icon m-b15 text-primary scale-in-center">
                                                        <span className="icon-cell"><img src={item.icon} alt=""/></span>
                                                    </div>
                                                    <div className="icon-content">
                                                        <h4 className="m-b25">{item.title}</h4>
                                                        <p>{item.description}</p>
                                                        {/* <NavLink to={"/services"} className="site-button-link" data-hover="Read More">Read More <i className="fa fa-angle-right arrow-animation" /></NavLink> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Specialization4;