import React, { useEffect, useState } from 'react';

var img1 = require('./../../images/background/bg-6.png');
var img2 = require('./../../images/background/line.png');

const AboutSummary  = ({about})=> {
    useEffect(() => {
        function loadScript(src) {

            return new Promise(function(resolve, reject){
              var script = document.createElement('script');
              script.src = src;
              script.addEventListener('load', function () {
                resolve();
              });
              script.addEventListener('error', function (e) {
                reject(e);
              });
              document.body.appendChild(script);
              document.body.removeChild(script);
            })
          };

        loadScript('./assets/js/masonary.js');

    }, [])

    console.log('About Summary ', about)
    return (
            <>
                <div className="section-full p-t80 p-b80 bg-dark bg-repeat square_shape2 inner-page-padding" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="mt-separator-outer separator-left">
                                <div className="mt-separator text-white">
                                    <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">About</span> Summary</h2>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content our-story">
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img src={require('./../../images/about/journey.jpg')} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover" style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                        {/* <h4 className="text-uppercase m-b20">2011-12</h4> */}
                                        <h4 className="m-b20">Our Journey</h4>
                                        <p>
                                         {about &&
                                            about.vision ? about.vision
                                                :`

                                                Laffo Engineering PLC was founded in 2014 as a response to Ethiopia’s growing demand for reliable suppliers in key sectors. Over the years, we have expanded our services and product offerings, becoming a leader in the supply of laboratory equipment, medical devices, construction materials, and industrial raw materials. Our commitment to quality and customer satisfaction has driven our success, and we continue to strive for excellence in all that we do.
                                                `
                                         }
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img src={require('./../../images/about/values.png')} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover"  style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                        <h4 className="m-b20">Our Values</h4>
                                        <p>
                                            {about &&
                                                about.our_values?
                                                about.our_values
                                                :`
                                                
                                                At Laffo Engineering PLC, our core values guide everything we do. We uphold the highest ethical standards, conducting our business with integrity, transparency, and fairness in all dealings. Quality is at the forefront of our operations, as we strive to deliver only the best products and services to our clients. We embrace innovation, always seeking new ways to enhance our offerings and adapt to the evolving needs of our clients. Above all, we are customer-focused, working closely with each client to understand their unique requirements and provide tailored solutions that exceed their expectations.
                                                `
                                            }
                                          </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img style={{height: '250px'}} src={require('./../../images/about/mission.jpg')} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover"  style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                        <h4 className="m-b20">Our Mission</h4>
                                        <p>
                                              {about &&
                                                about.mission?
                                                about.mission
                                                :`

                                                 Our mission is to be a catalyst for Ethiopia's national development by providing high-quality,
                                                innovative materials and services that cater to the essential needs of various sectors.
                                                We are committed to supporting the educational, healthcare, construction, and industrial
                                                sectors by offering products that meet the highest standards of excellence.
                                                Through our dedication to quality, integrity, and customer satisfaction,
                                                we aim to create long-lasting partnerships that contribute to the growth and prosperity of Ethiopia,
                                                while continuously enhancing the quality of life for its citizens.
                                                `
                                              }
                                             </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img style={{height: '250px'}
                                            } src={require('./../../images/about/team.jpg')} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving  bg-cover"
                                    style={{
                                        backgroundImage: 'url(' + img2 + ')',
                                         }}

                                    >
                                        <h4 className="m-b20">Our Team</h4>
                                        <p>
                                         {
                                            about &&
                                            about.team?
                                            about.team
                                            :`

                                             Our team is composed of dedicated professionals with diverse expertise across various
                                            industries, all driven by a shared commitment to excellence.
                                            We work collaboratively to ensure that every project is executed with precision, and we
                                            continuously strive to exceed client expectations by delivering innovative,
                                            high-quality solutions. Our team's dedication to integrity, quality, and client
                                            satisfaction is at the core of everything we do.
                                            `
                                         }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
};

export default AboutSummary;