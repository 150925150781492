import React,{useEffect,useState} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// const testimonials = [
//     {
//         image: require('./../../images/testimonials/pic1.jpg'),
//         reviewername: 'Shelly Johnson',
//         position: 'Business Person',
//         review: 'Excellent Customer support!. The template itself is very ext ended. simply dummy text of the printing and industry. the printing and typesetting industry. Lorem Ipsum has been the industry\'s'
//     },
//     {
//         image: require('./../../images/testimonials/pic2.jpg'),
//         reviewername: 'Cuthbert Brain',
//         position: 'Contractor',
//         review: 'The template itself is very ext ended. excellent customer support! simply dummy text of the printing and industry. the printing and typesetting industry. Lorem Ipsum has been the industry\'s'
//     },
//     {
//         image: require('./../../images/testimonials/pic3.jpg'),
//         reviewername: 'Cathrine Wagner',
//         position: 'Builder',
//         review: 'The template itself is very ext ended. excellent customer support! simply dummy text of the printing and industry. the printing and typesetting industry. Lorem Ipsum has been the industry\'s'
//     },
//     {
//         image: require('./../../images/testimonials/pic4.jpg'),
//         reviewername: 'John Doe',
//         position: 'Business Person',
//         review: 'Excellent Customer support!. The template itself is very ext ended. simply dummy text of the printing and industry. the printing and typesetting industry. Lorem Ipsum has been the industry\'s'
//     },
//     {
//         image: require('./../../images/testimonials/pic5.jpg'),
//         reviewername: 'Cuthbert Brain',
//         position: 'Business Person',
//         review: 'The template itself is very ext ended. excellent customer support!.  simply dummy text of the printing and industry. the printing and typesetting industry. Lorem Ipsum has been the industry\'s'
//     }
// ]

const testimonials = [
    {
        image: require('./../../images/testimonials/abebe.png'),
        reviewername: 'Abebe Tesfaye',
        position: 'Business Owner',
        review: 'LAFFO Engineering has been a reliable partner for our business. Their products and customer service are exceptional. We highly recommend them for any engineering and supply needs in Ethiopia.'
    },
    {
        image: require('./../../images/testimonials/bekele.png'),
        reviewername: 'Mulu Alemayehu',
        position: 'Contractor',
        review: 'The quality of materials provided by LAFFO Engineering is top-notch. Their dedication to customer satisfaction sets them apart from others in the industry. It’s been a pleasure working with them.'
    },
    {
        image: require('./../../images/testimonials/mulu.png'),
        reviewername: 'Bekele Tadesse',
        position: 'Construction Manager',
        review: 'I am extremely impressed with the range of products and the professional service provided by LAFFO Engineering. They are truly committed to supporting Ethiopia’s development with high-quality supplies.'
    }
];

var bnr1 = require('./../../images/background/bg6.jpg');

const Testimonials = ({partners}) => {




        const options = {
            loop:true,
            autoplay:true,
            margin:30,
            nav:false,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:2
                },
                768:{
                    items:3
                },
                991:{
                    items:5
                },
                1200:{
                    items:7
                }

            }
        };

        const [partnersData, setPartnersData] = useState(null);
        useEffect(()=>{
            function loadScript(src) {

                return new Promise(function(resolve, reject){
                  var script = document.createElement('script');
                  script.src = src;
                  script.addEventListener('load', function () {
                    resolve();
                  });
                  script.addEventListener('error', function (e) {
                    reject(e);
                  });
                  document.body.appendChild(script);
                  document.body.removeChild(script);
                })
              };

            loadScript('./assets/js/masonary.js');
        },[]);
        useEffect(()=>{
            setPartnersData(partners);
        },[partners])

        console.log("P",partners)

        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b50 square_shape2 bg-cover" style={{backgroundImage:"url(" + bnr1 + ")"}}>
                    <div className="container">
                        <div className="section-content">
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className="mt-separator-outer separator-center">
                                    <div className="mt-separator">
                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> Partners</h2>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                            {/* TESTIMONIAL START */}
                            <OwlCarousel className="owl-carousel testimonial-home" {...options}>

                                {
                                    partners && partners.length>0 ?
                                        (partners.map((item, index) => (
                                            <div key={index} className="item">
                                                <div className="testimonial-2 m-a30 hover-animation-1">
                                                    <div className=" block-shadow bg-white p-a30">
                                                        <div className="testimonial-detail  clearfix" >
                                                            <div className="testimonial-pic radius shadow scale-in-center" >
                                                               <a href={item.link} target='_blank' title={item.name}>

                                                                <img
                                                                    src={ `${process.env.REACT_APP_API_URL}/storage/${item.banner}` }
                                                                    style={{height:'100%', width:'100%'}} alt=""/>
                                                                </a>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ):(
                                        testimonials.map((item, index) => (
                                            <div key={index} className="item">
                                                <div className="testimonial-2 m-a30 hover-animation-1">
                                                    <div className=" block-shadow bg-white p-a30">
                                                    <div className="shimmer-effect">
                                                        <div className="shimmer shimmer-image-small" />
                                                        {/* <div className="shimmer shimmer-text" /> */}
                                                        {/* <div className="shimmer shimmer-text" style={{ width: '80%' }} /> */}
                                                        {/* <div className="shimmer shimmer-icons" /> */}
                                                    </div>

                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )
                                }
                            </OwlCarousel>


                        </div>
                    </div>
                    <div className="hilite-title text-left p-l50 p-t80 text-uppercase text-pop-up-top">
                        <strong>Partners</strong>
                    </div>
                </div>
            </>
        );
};

export default Testimonials;