import React from 'react';
import { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { servicesData } from '../../data';

const servicesD  = servicesData;

var img1 = require('./../../images/background/bg-5.png');
var img2 = require('./../../images/background/line.png');

const AllServices = ({services}) => {
   

    const truncateText = (text) => {
        const maxLength = 100;
        if (text.length > maxLength) {
          return text.substring(0, maxLength) + '...';
        }
        return text;
      };

    useEffect(() => {
        function loadScript(src) {
           
            return new Promise(function(resolve, reject){
              var script = document.createElement('script');
              script.src = src;
              script.addEventListener('load', function () {
                resolve();
              });
              script.addEventListener('error', function (e) {
                reject(e);
              });
              document.body.appendChild(script);
              document.body.removeChild(script);
            })
          };
   
        loadScript('./assets/js/masonary.js');
    },[])

    return (
        <>
            <div className="section-full p-t80 p-b50 bg-white mobile-page-padding" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                <div className="container">
                    {/* TITLE START */}
                    <div className="section-head">
                        <div className="mt-separator-outer separator-center">
                            <div className="mt-separator">
                                <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">All</span> Services</h2>
                            </div>
                        </div>
                    </div>
                    <div className="section-content">
                        <div className="row">
                        {
                            services && services.length>0?
                            (services.map((item, index) => (
                            <div key={index} className="col-md-4 col-sm-6">
                                <div className="mt-icon-box-wraper p-a10 center m-b30 box-shadow bg-white" style={{ display: 'flex', flexDirection: 'column', height: '350px' }}>
                                    <div className="mt-icon-box-sm inline-icon text-primary m-b20 radius bg-primary scale-in-center bg-moving" 
                                        style={{ backgroundImage: 'url(' + img2 + ')', width: '100px', height: '100px', margin: '0 auto' }}>
                                        <span className="icon-cell text-secondry">
                                            <img style={{ width: '100px', height: '100px', borderRadius: '50%', objectFit: 'cover' }} src={ `${process.env.REACT_APP_API_URL}/storage/${item.banner}` } alt={item.title} />
                                        </span>
                                    </div>

                                    <div className="icon-content" style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                                        <p className="mt-tilte font-weight-600 m-b10">{item.serviceName}</p>
                                        <p dangerouslySetInnerHTML={{ __html: truncateText(item.short_description) }}></p>
                                        <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }}>
                                            <NavLink to={`/services/${item.slug}`} className="site-button-link" data-hover="Read More">Read More <i className="fa fa-angle-right arrow-animation" /></NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))):
                        (servicesD.map((item, index) => (
                            <div key={index} className="col-md-4 col-sm-6" style={{padding:'2px'}}>
                            <div className="shimmer-effect" >
                                <div className="shimmer shimmer-image" />
                                <div className="shimmer shimmer-text" />
                                <div className="shimmer shimmer-text" style={{ width: '80%' }} />
                                <div className="shimmer shimmer-icons" />
                            </div>
                            </div>
                        )))
                        
                        }

                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AllServices;