import React,{useState,useEffect} from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import axios from 'axios';

const bnrimg = require('./../../images/contact/banner.jpg');

const ContactUs = () => {
    const defaultProps = {
        center: {
            lat: 34.073280,
            lng: -118.251410
        },
        zoom: 12
    };

    const [about, setAbout] = useState({})

    useEffect(() => {
        const fetchAbout = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/business-setup`);
            setAbout(response.data);
            console.log("Business Setup : ",response.data);
        };
        fetchAbout();
    }, []);

    const mapUrl = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d156987.04842419925!2d38.69574379113751!3d8.963167731090333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b85cef5ab402d%3A0x8467b6b037a24d49!2sAddis%20Ababa!5e1!3m2!1sen!2set!4v1724220166217!5m2!1sen!2set";

    const contactInfo = {
        phone: [
            "+251 944 257 225",
            "+251 116 395 250",
            "+251 911 168 287"
        ],
        email: "info@laffoeng.com",
        address: [
            "Alfoz Plaza Building Addis Ababa",
            "Awi-Hakim Building, Addis Ababa"
        ]
    };

    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        message: ''
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [sending, setSending] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setSending(true);

        console.log(formData);

         axios.post(`${process.env.REACT_APP_API_URL}/api/contacts`,formData)
                .then((response)=>{
                    console.log(response.data);
                    setSending(false);
                    setFormData({
                        full_name: '',
                        email: '',
                        message: ''
                    })

                    setSuccessMessage('Thank you for your message. We will get back to you soon.')
                }).catch((err)=>{
                    console.log(err)
                    setSending(false);
                    setErrorMessage('Something Went Wrong.')
                })

    };

    return (
        <>
            <Header />
            <div className="page-content">
                <Banner title="Connecting You to Solutions – Reach Out, We’re Here to Help!" pagename="Contact Us" bgimage={bnrimg} />

                {/* SECTION CONTENT START */}
                <div className="section-full p-tb80 inner-page-padding">
                    {/* LOCATION BLOCK */}
                    <div className="container">
                        {/* GOOGLE MAP & CONTACT FORM */}
                        <div className="section-content">
                            {/* CONTACT FORM */}
                            <div className="row">
                                <div className="col-md-8 col-sm-6">
                                    <form className="contact-form cons-contact-form" >
                                        <div className="contact-one m-b30">
                                            {/* TITLE START */}
                                            <div className="section-head">
                                                <div className="mt-separator-outer separator-left">
                                                    <div className="mt-separator">
                                                        <h2 className="text-uppercase sep-line-one">
                                                            <span className="font-weight-300 text-primary">Get</span> In touch
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{
                                                padding:"10px 0px",
                                                display:"flex",
                                                justifyContent:"center",
                                                alignItems:"center"
                                            }}>
                                            {successMessage && <p className="success-message" style={{color:"green",fontWeight:"bold"}}> {successMessage}</p>}
                                            {errorMessage && <p className="error-message" style={{color:"red",fontWeight:"bold"}}>{errorMessage}</p>}
                                            </div>
                                            {/* TITLE END */}
                                            <div className="form-group">
                                                <input
                                                    name="full_name"
                                                    type="text"
                                                    required
                                                    className="form-control"
                                                    placeholder="Name"
                                                    value={formData.full_name}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <input
                                                    name="email"
                                                    type="email"
                                                    className="form-control"
                                                    required
                                                    placeholder="Email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <textarea
                                                    name="message"
                                                    rows={4}
                                                    className="form-control"
                                                    required
                                                    placeholder="Message"
                                                    value={formData.message}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="text-right">
                                            <button
                                                onClick={handleSubmit}
                                                    disabled={sending}
                                                className="site-button btn-effect"
                                            >
                                                {sending ? 'Submitting' : 'Submit'}
                                            </button>

                                            </div>

                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="contact-info m-b30">
                                        {/* TITLE START */}
                                        <div className="section-head">
                                            <div className="mt-separator-outer separator-left">
                                                <div className="mt-separator">
                                                    <h2 className="text-uppercase sep-line-one ">
                                                        <span className="font-weight-300 text-primary">Contact</span> Info
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                        {/* TITLE END */}
                                        <div className="bg-dark p-a20 text-white">
                                            <div className="mt-icon-box-wraper left p-b20">
                                                <div className="icon-xs"><i className="fa fa-phone" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Phone number</h5>
                                                    <p>

                                                        {
                                                            about && about.phone1 &&
                                                            about.phone1
                                                        }
                                                        <br />

                                                        {
                                                            about && about.phone2 &&
                                                            about.phone1
                                                        }
                                                        {contactInfo.phone[2]}
                                                      
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="mt-icon-box-wraper left p-b20">
                                                <div className="icon-xs"><i className="fa fa-envelope" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Email address</h5>
                                                    <p>{about && about.email &&
                                                        about.email}</p>
                                                </div>
                                            </div>
                                            <div className="mt-icon-box-wraper left">
                                                <div className="icon-xs"><i className="fa fa-map-marker" /></div>
                                                <div className="icon-content">
                                                    <h5 className="m-t0 font-weight-500">Address info</h5>
                                                    <p>
                                                        {contactInfo.address[0]}
                                                        <br />
                                                        {contactInfo.address[1]}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="gmap-outline">
                            <div>
                                <iframe
                                    title='iframe'
                                    src={about && about.gmapUrl ? about.gmapUrl : mapUrl}
                                    height="450"
                                    style={{ border: 0, width: '100%' }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>
                {/* SECTION CONTENT END */}
            </div>

            <Footer />
        </>
    );
};

export default ContactUs;
