import React from 'react';
import { NavLink } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import { productsData } from '../../data';

var bnrimg = require('./../../images/banner/3.jpg');

const products = productsData;

const  ProjectCarousel = ({featured_product})=> {
        
        const options = {
            loop:true,
            autoplay:false,
            center: false,
            items:3,
            margin:40,
            nav:true,
            dots: false,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                768:{
                    items:2
                },			
                991:{
                    items:3
                },
                1200:{
                    items:4
                }			
                
            }
        };

        console.log(featured_product)
        
        const truncateText = (text) => {
            const maxLength = 60;
            if (text.length > maxLength) {
              return text.substring(0, maxLength) + '...';
            }
            return text;
          };

          const truncateTitle = (text) => {
            const maxLength = 20;
            if (text.length > maxLength) {
              return text.substring(0, maxLength) + '...';
            }
            return text;
          };

        return (
            <>
                {/* <Header /> */}
                <div className="page-content">
                {/* <Banner title="Broad vision. Careful thought. Hand-crafted design." pagename="Project carousel" bgimage={bnrimg}/> */}
                
                    {/* SECTION CONTENT START */}
                    <div className="section-full p-tb80 bg-gray inner-page-padding">
                        <div className="container-fluid">
                        <div className="section-head">
                            <div className="mt-separator-outer separator-left">
                                <div className="mt-separator">
                                    <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> Featured Products</h2>
                                </div>
                            </div>
                        </div>
                            <div className="section-content">
                                <div className="work-carousel-outer">
                                    <OwlCarousel className="owl-carousel work-carousel owl-btn-vertical-center" {...options}>
                                    {
                                        featured_product && featured_product.length>0 ?
                                        (
                                            featured_product.map((item, index) => (
                                            <div key={index} className="item mt-box">
                                                <div className="mt-img-effect mt-img-overlay7">
                                                    <img 
                                                    src={ `${process.env.REACT_APP_API_URL}/storage/${item.banner}` }
                                                    style={{height:'250px'}} alt="" />
                                                </div>
                                                <div className="mt-info p-a30">
                                                    <h4 className="m-b20 m-t0"><NavLink to={`/products/${item.slug}`}>{truncateTitle(item.title)}</NavLink></h4>
                                                    <p dangerouslySetInnerHTML={{ __html: truncateText(item.description) }}></p>
                                                </div>
                                            </div>
                                        ))
                                        ):(
                                            products.map((item, index) => (
                                            <div key={index} className="item mt-box">
                                                <div className="mt-img-effect mt-img-overlay7">
                                                    <img src={item.image} style={{height:'250px'}} alt="" />
                                                </div>
                                                <div className="mt-info p-a30">
                                                    <h4 className="m-b20 m-t0"><NavLink to={`/products/${item.slug}`}>{truncateTitle(item.title)}</NavLink></h4>
                                                    <p>{truncateText(item.short_description) }</p>
                                                    {/* <p dangerouslySetInnerHTML={{ __html: truncateText(item.description) }}></p> */}

                                                    {/* <NavLink to={`/product/${item.slug}`} className="site-button btn-effect">Read More</NavLink> */}
                                                </div>
                                            </div>
                                        ))
                                        )
                                    }
                                        
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    <div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
                        <strong>Products</strong>
                    </div>
                    </div>
                    {/* SECTION CONTENT END  */}
                </div>

                {/* <Footer /> */}
            </>
        );
};

export default ProjectCarousel;