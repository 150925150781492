import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';

var img1 = require('./../../images/background/bg-4.png');
var img2 = require('./../../images/background/bg-site.png');

const OurValue3 = ({about}) => {
    


    useEffect(() => {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

    }, [])

        return (
            <>
                <div className="section-full p-t80 p-b50 bg-gray bg-no-repeat bg-bottom-right mobile-page-padding" style={{ backgroundImage: 'url(' + img1 + ')' }}>
                    <div className="container">
                        <div className="section-content ">
                            <div className="row">
                                <div className="col-md-7 col-sm-6  m-b30">
                                    <div className="brochur bg-dark p-a20">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="p-a30 bg-primary bg-bottom-right" style={{ backgroundImage: 'url(' + img2 + ')' }}>
                                                    <div className="text-black">
                                                        <h4 className="mt-tilte m-t0">Laffo Engineering</h4>
                                                        <p>Discover our journey, values, and commitment to delivering top-quality products and services. </p>
                                                        <NavLink to="/about" className="site-button-secondry btn-effect"><span> About Us</span></NavLink>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="text-white p-r15">
                                                    <h4 className="mt-tilte">Let's help you!</h4>
                                                    <p>We are always here to help you with your needs. Whether you need more information about our products and services or want to discuss a specific project, feel free to reach out to us.</p>
                                                    <NavLink to="/contactus" className="site-button btn-effect">Contact Us</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-6 m-b30">
                                    <div className="our-exp">
                                        <div className="mt-box">
                                            <h3 className="m-t0"><span className="font-weight-100"> We have </span><span className="text-primary">more than {' '}{about && about.experience ? about.experience : 10} {' '} years of </span> experience</h3>
                                        </div>
                                        <span className="progressText text-black"><b>Products Type</b></span>
                                        <div className="progress mt-probar-1  m-b30 m-t10">
                                            <div className="progress-bar bg-primary " role="progressbar" aria-valuenow={about&& about.product_types?about.product_types:95} aria-valuemin={0} aria-valuemax={100}>
                                                <span className="popOver" data-toggle="tooltips" data-placement="top"   title="500 +" />
                                            </div>
                                        </div>
                                        <span className="progressText text-black"><b>Client Satisfaction Rate</b></span>
                                        <div className="progress mt-probar-1 m-b30 m-t10">
                                            <div className="progress-bar bg-primary" role="progressbar" aria-valuenow={about&& about.satisfaction_rate?about.satisfaction_rate:95} aria-valuemin={10} aria-valuemax={100}>
                                                <span className="popOver" data-toggle="tooltips" data-placement="top" title={`${about && about.satisfaction_rate ? about.satisfaction_rate : 95} +`} />
                                            </div>
                                        </div>
                                        <span className="progressText text-black"><b>Service Categories</b></span>
                                        <div className="progress mt-probar-1 m-b30 m-t10">
                                            <div className="progress-bar bg-primary" role="progressbar" aria-valuenow={about&& about.categories?about.categories:95} aria-valuemin={0} aria-valuemax={100}>
                                                <span className="popOver" data-toggle="tooltips" data-placement="top" title={`${about && about.categories ? about.categories : 9} +`}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
};

export default OurValue3;