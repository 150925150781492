import React, { useState, useEffect } from 'react';
import Header from '../Common/Header';
import Footer from '../Common/Footer';
import Banner from '../Elements/Banner';
import axios from 'axios';
import OurValue3 from '../Elements/OurValueD';
import ProductPage from './ProductPage';
var bnrimg = require('./../../images/products/banner.jpg');

const Products = () => {
    
    const [about, setAbout] = useState({});
   
    useEffect(() => {
        const fetchAbout = async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/about_info`);
            setAbout(response.data);
            console.log("About Info : ",response.data);
        };
        fetchAbout();

    }, []);
    return (
        <>
            <Header />
            <div className="page-content">
                <Banner title="Creating quality urban lifestyles, building stronger communities." pagename="Products" bgimage={bnrimg} />
                <ProductPage/>
                <OurValue3 about={about}/>
            </div>
            <Footer />
        </>
    );
};

export default Products;
